import { Component, Input, OnInit } from '@angular/core'
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { LeaveService } from 'src/app/services/leave.service'
import { LeaveForm } from 'src/app/services/interface/leave.model'
import { NzUploadChangeParam, NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload'
import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http'
import { ApiService } from 'src/app/services/api.service'
import storeeeee from 'store'
import { Observable, Observer } from 'rxjs'

interface options {
  id: string
  name: string
}

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

@Component({
  selector: 'app-edit-leave',
  templateUrl: './edit-leave.component.html',
})
export class EditLeaveComponent implements OnInit {
  @Input() id: string
  head: boolean
  hr: boolean
  applicantHR: boolean
  applicantHead: boolean
  form: FormGroup
  isLoading: boolean = false
  fileList: NzUploadFile[] = []
  statusFile: any = null
  head_status: string
  hr_status: string
  statusArr: options[] = [
    {
      id: 'true',
      name: 'Approve',
    },
    {
      id: 'false',
      name: 'Reject',
    },
  ]

  constructor(
    private fb: FormBuilder,
    private leaveService: LeaveService,
    private apiService: ApiService,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modal: NzModalRef,
    private http: HttpClient,
  ) {
    this.form = this.fb.group({
      name: [null, [Validators.required]],
      days: [null, [Validators.required]],
      date: this.fb.array([]),
      substitute: [null, [Validators.required]],
      leave_notes: [null, [Validators.required]],
      hr_status: [null],
      hr_notes: [null],
      head_status: [null],
      head_notes: [null],
      code: [null, [Validators.required]],
      status_img_hr: [null],
    })
  }

  ngOnInit(): void {
    this.fetchLeaveDetails()

    this.form.statusChanges.subscribe(state => {
      this.modal.updateConfig({ nzOkDisabled: state === 'INVALID' })
    })

    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }
  }

  get dateArray(): FormArray {
    return this.form.controls['date'] as FormArray
  }

  fetchLeaveDetails() {
    this.isLoading = true
    this.leaveService.leaveDetail(this.id).subscribe(
      r => {
        for (let i = 0; i < r.days; i++) {
          this.dateArray.push(new FormControl(r.date[i], [Validators.required]))
        }
        if (r.status_img_hr !== null) {
          this.fileList = [r.status_img_hr]
          r.status_img_hr = true
          this.statusFile = true
        }
        this.form.patchValue(r)
        this.head = r.head
        this.hr = r.hr
        this.applicantHead = r.applicantHead
        this.applicantHR = r.applicantHR
        this.head_status = r.head_status
        this.hr_status = r.hr_status
        for (const i in this.form.controls) {
          if (this.form.controls.hasOwnProperty(i)) {
            this.form.controls[i].markAsDirty()
            this.form.controls[i].updateValueAndValidity()
          }
        }
        this.isLoading = !this.isLoading
      },
      err => {
        this.notification.error('Error', err.error.message)
      },
    )
  }

  cancel(): void {
    this.modal.destroy()
  }

  submitForm(): boolean {
    this.isLoading = true
    this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
    var isUploading = false
    for (let i = 0; i < this.fileList.length; i++) {
      if (['uploading', 'error'].includes(this.fileList[i].status)) {
        isUploading = true
        break
      }
      if (isUploading) break
    }

    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }

    if (!isUploading && this.form.valid) {
      var formSubs: LeaveForm = this.form.value
      formSubs.id = this.id
      if (this.fileList[0] != null) {
        if (this.fileList[0].status === 'done') {
          formSubs.status_img_hr = {
            name: this.fileList[0].response?.name || this.fileList[0].name,
            status: this.fileList[0].status,
            url: this.fileList[0].response?.url || this.fileList[0].url,
            uid: this.fileList[0].uid,
          }
        }
      }
      this.leaveService.editLeave(formSubs).subscribe(
        r => {
          this.notification.success('Success', r.message)
          this.modal.destroy()
          this.isLoading = false
          return true
        },
        err => {
          this.notification.error('Error', err.error.message)
          this.isLoading = false
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          return false
        },
      )
    } else {
      this.msg.error(
        isUploading ? 'Please wait until all files uploaded' : 'Please Fill Blank Form',
      )
      this.isLoading = false
      this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
      return false
    }
  }

  handleBeforeUpload = (file: NzUploadFile): boolean => {
    if (file.size <= 11534336) {
      return true
    } else {
      this.msg.error('File too Large')
      return false
    }
  }

  customUploadReq = (item: NzUploadXHRArgs) => {
    const formData = new FormData()
    formData.append('file', item.file as any)
    const req = new HttpRequest(
      'POST',
      this.apiService.API_SUNSHINE_SERVER + '/api/upload/temp',
      formData,
      {
        headers: new HttpHeaders({
          authorization: 'Bearer ' + storeeeee.get('accessToken'),
        }),
        reportProgress: true,
        withCredentials: false,
      },
    )

    return this.http.request(req).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total > 0) {
            ;(event as any).percent = (event.loaded / event.total) * 100 // tslint:disable-next-line:no-any
          }
          item.onProgress!(event, item.file)
        } else if (event instanceof HttpResponse) {
          item.onSuccess!(event.body, item.file, event)
        }
      },
      err => {
        // console.log(err)
        /* error */
        item.onError!(err, item.file)
      },
    )
  }

  showUploadList = {
    showPreviewIcon: true,
    showDownloadIcon: true,
    showRemoveIcon: false,
  }

  handleRemove = (file: NzUploadFile) =>
    new Observable((observer: Observer<boolean>) => {
      this.form.patchValue({ image: null })
      observer.next(true)
      observer.complete()
    })

  handleDownload = (file: NzUploadFile): void => {
    window.open((file.response?.url || file.url) + '?s=download', '_blank')
  }

  previewImage: string | undefined = ''
  previewVisible = false

  handlePreview = async (file: NzUploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj!)
    }
    this.previewImage = file.response?.url || file.url || file.preview
    this.previewVisible = true
  }

  handleChange(info: NzUploadChangeParam): void {
    if (info.file.status === 'done') {
      this.msg.success(`${info.file.name} file uploaded successfully`)
      this.form.patchValue({ image: true })
    } else if (info.file.status === 'error') {
      this.msg.error(`${info.file.name} file upload failed.`)
    }
  }
}
