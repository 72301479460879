import { Component, Input, OnInit } from '@angular/core'
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzUploadFile } from 'ng-zorro-antd/upload'
import { LeaveService } from 'src/app/services/leave.service'
import { ScheduleRoomService } from 'src/app/services/schedule-room.service'

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

@Component({
  selector: 'app-view-leave',
  templateUrl: './view-leave.component.html',
})
export class ViewLeaveComponent implements OnInit {
  @Input() id: string
  form: FormGroup
  isLoading: boolean = false
  user: any
  head_status: string
  hr_status: string
  head: boolean
  hr: boolean
  fileList: NzUploadFile[] = []
  statusFile: any = null

  constructor(
    private fb: FormBuilder,
    private leaveService: LeaveService,
    private scheduleRoomService: ScheduleRoomService,
    private notification: NzNotificationService,
  ) {
    this.form = this.fb.group({
      name: [null, [Validators.required]],
      days: [null, [Validators.required]],
      date: this.fb.array([]),
      substitute: [null, [Validators.required]],
      leave_notes: [null, [Validators.required]],
      hr_status: [null, [Validators.required]],
      hr_notes: [null, [Validators.required]],
      head_status: [null, [Validators.required]],
      head_notes: [null, [Validators.required]],
      code: [null, [Validators.required]],
      status_img_hr: [null, [Validators.required]],
      created_by: [null, [Validators.required]],
      updated_by: [null, [Validators.required]],
    })

    this.scheduleRoomService.username().subscribe(data => {
      this.form.get('name').patchValue(data.name)
      this.form.get('code').patchValue(data.code)
      this.user = data
    })
  }

  ngOnInit(): void {
    this.fetchLeaveDetails()
  }

  get dateArray(): FormArray {
    return this.form.controls['date'] as FormArray
  }

  fetchLeaveDetails() {
    this.isLoading = true
    this.leaveService.leaveDetail(this.id).subscribe(
      r => {
        for (let i = 0; i < r.days; i++) {
          this.dateArray.push(new FormControl(r.date[i], [Validators.required]))
        }
        if (r.status_img_hr !== null) {
          this.fileList = [r.status_img_hr]
          r.status_img_hr = true
          this.statusFile = true
        }
        this.form.patchValue(r)
        this.head_status = r.head_status
        this.hr_status = r.hr_status
        this.head = r.head
        this.hr = r.hr
        this.isLoading = !this.isLoading
      },
      err => {
        this.notification.error('Error', err.error.message)
      },
    )
  }

  showUploadList = {
    showPreviewIcon: true,
    showDownloadIcon: true,
    showRemoveIcon: false,
  }

  handleDownload = (file: NzUploadFile): void => {
    window.open((file.response?.url || file.url) + '?s=download', '_blank')
  }

  previewImage: string | undefined = ''
  previewVisible = false

  handlePreview = async (file: NzUploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj!)
    }
    this.previewImage = file.response?.url || file.url || file.preview
    this.previewVisible = true
  }
}
