<nz-spin [nzSpinning]="isLoading">
    <form nz-form [formGroup]="form">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <nz-form-item>
                    <nz-form-label nzFor="name" class="text-left">Name</nz-form-label>
                    <nz-form-control>
                        <input readonly class="content" nz-input id="'name'" formControlName="name" />
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-lg-6 col-md-12">
                <nz-form-item>
                    <nz-form-label nzFor="code" class="text-left">Employee Code</nz-form-label>
                    <nz-form-control>
                        <input readonly class="content" nz-input id="'code'" formControlName="code" />
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-lg-6 col-md-12">
                <nz-form-item>
                    <nz-form-label nzFor="days" class="text-left">Leave Days</nz-form-label>
                    <nz-form-control nzErrorTip="Please input amount of days!">
                        <input class="content" readonly nz-input type="number" min="1" id="'days'"
                            formControlName="days" />
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="substitute" class="text-left">Substitute</nz-form-label>
                    <nz-form-control nzErrorTip="Please choose a substitute!">
                        <input class="content" nz-input id="substitute" readonly formControlName="substitute" />
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-lg-6" style="overflow-y:scroll;height: 100px;">
                <ng-container formArrayName="date">
                    <div *ngFor="let _ of dateArray.controls; index as i">
                        <nz-form-item>
                            <nz-form-label nzFor="date" class="text-left">Date</nz-form-label>
                            <nz-form-control nzErrorTip="Please choose correct date!">
                                <nz-date-picker nzDisabled [formControlName]="i" nzFormat="dd-MM-yyyy"></nz-date-picker>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </ng-container>
            </div>
            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="leave_notes" class="text-left">Leave Notes</nz-form-label>
                    <nz-form-control nzErrorTip="Please input a valid leave notes">
                        <nz-textarea-count>
                            <textarea rows="4" readonly formControlName="leave_notes" nz-input></textarea>
                        </nz-textarea-count>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div *ngIf="(head !== true) && (head_status !== 'Pending'); else wait4Head" class="row">
            <nz-divider></nz-divider>
            <div class="col-12">
                <h5>Approval By Head</h5>
            </div>
            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="head_status" class="text-left">Status</nz-form-label>
                    <nz-form-control>
                        <input class="content" readonly nz-input id="'head_status'" formControlName="head_status" />
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="head_notes" class="text-left">Status Notes</nz-form-label>
                    <nz-form-control nzErrorTip="Please input a valid reason for status">
                        <nz-textarea-count>
                            <textarea rows="4" readonly formControlName="head_notes" nz-input></textarea>
                        </nz-textarea-count>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <ng-template #wait4Head>
            <div *ngIf="head !== true">
                <nz-divider nzText="Awaiting Approval from Head"></nz-divider>
            </div>
        </ng-template>
        <div *ngIf="(hr_status !== 'Pending'); else wait4Hr" class="row">
            <nz-divider></nz-divider>
            <div class="col-12">
                <h5>Approval By HR</h5>
            </div>
            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="hr_status" class="text-left">Status</nz-form-label>
                    <nz-form-control>
                        <input class="content" readonly nz-input id="'hr_status'" formControlName="hr_status" />
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="hr_notes" class="text-left">Status Notes</nz-form-label>
                    <nz-form-control nzErrorTip="Please input a valid reason for status">
                        <nz-textarea-count>
                            <textarea rows="4" readonly formControlName="hr_notes" nz-input></textarea>
                        </nz-textarea-count>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div *ngIf="statusFile !== null">
                <nz-form-label nzFor="status_img" class="text-left">Status Image</nz-form-label>
                <nz-upload nzListType="picture-card" [(nzFileList)]="fileList" [nzPreview]="handlePreview"
                    [nzDownload]="handleDownload" [nzAccept]="'image/jpeg, image/jpg, image/png'"
                    [nzFileType]="'image/jpeg, image/jpg, image/png'" [nzDisabled]="fileList.length > 0"
                    [nzShowButton]="!(fileList.length > 0)" [nzShowUploadList]="showUploadList">
                </nz-upload>
                <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
                    (nzOnCancel)="previewVisible = false">
                    <ng-template #modalContent>
                        <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
                    </ng-template>
                </nz-modal>
            </div>
        </div>
        <ng-template #wait4Hr>
            <div *ngIf="((head_status !== 'Pending') || (head === true))">
                <nz-divider nzText="Awaiting Approval from HR"></nz-divider>
            </div>
        </ng-template>
    </form>
</nz-spin>