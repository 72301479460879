<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <nz-table #fixedTable [nzShowSizeChanger]="false" class="table-driver" [nzData]="listOfData"
          [nzFrontPagination]="false" [nzLoading]="isLoading" [nzTotal]="totalData" [nzPageSize]="pageSize"
          [nzPageIndex]="pageIndex" (nzQueryParams)="onQueryParamsChange($event)" [nzScroll]="{ y: '42vh' }"
          [nzTitle]="headerTable" nzSize="small">
          <thead>
            <tr>
              <ng-container *ngFor="let column of listOfColumns; trackBy: trackByName">
                <th *ngIf="column.sortFn" [nzLeft]="column.left" [nzRight]="column.right" [nzSortFn]="column.sortFn"
                  [(nzSortOrder)]="column.sortOrder" [nzColumnKey]="column.key" [nzWidth]="column.width">
                  {{ column.name }}
                </th>
                <th *ngIf="!column.sortFn" [nzLeft]="column.left" [nzRight]="column.right" [nzColumnKey]="column.key"
                  [nzWidth]="column.width">
                  {{ column.name }}
                </th>
              </ng-container>
            </tr>
          </thead>
          <nz-modal [nzContent]="modalContent" [nzFooter]="null">
            <ng-template #modalContent>
              <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
            </ng-template>
          </nz-modal>
          <tbody>
            <tr *ngFor="let data of fixedTable.data">
              <ng-container *ngFor="let column of listOfColumns; trackBy: trackByName">
                <td *ngIf="column.key == 'actions'" [nzLeft]="column.left" [nzRight]="column.right">
                  <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="View Leave Request">
                    <a class="btn btn-sm btn-light mr-2" (click)="viewLeave(data.id)">
                      <i class="fe fe-eye"></i>
                    </a>
                  </span>
                </td>
                <td *ngIf="column.key != 'actions'" [nzLeft]="column.left" [nzRight]="column.right">
                  {{ data[column.key] }}
                </td>
              </ng-container>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</div>

<ng-template #headerTable>
  <nz-spin [nzSpinning]="isDownloading">
    <div class="row justify-content-between">
      <div class="col">
        <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Request New Leave">
          <a class="btn btn-lg btn-light mr-2" (click)="addLeave()">
            <i class="fe fe-plus-circle"></i>
          </a>
        </span>
      </div>
    </div>
  </nz-spin>
</ng-template>
<router-outlet></router-outlet>