import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ApiService } from './api.service'
import { Observable } from 'rxjs'
import store from 'store'
import { LeaveForm } from './interface/leave.model'
import { QueryTable } from './interface/general.model'

@Injectable({
  providedIn: 'root',
})
export class LeaveService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  subsOption(value: string, type: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/leave/substitute-list',
      { value, type },
      params,
    )
  }

  applyLeave(form: LeaveForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/leave/apply-leave',
      form,
      params,
    )
  }

  listLeaveUser(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/leave/list-leave-user',
      queryTable,
      params,
    )
  }

  leaveDetail(id: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            id: id,
          },
        }
      : {}
    return this.http.get(this.apiService.API_SUNSHINE_SERVER + '/api/leave/leave-detail', params)
  }

  listLeave(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/leave/list-leave-hr',
      queryTable,
      params,
    )
  }

  editLeave(form: LeaveForm): Observable<any> {
    // form.name = form.name.toUpperCase().trim()
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/leave/edit-leave',
      form,
      params,
    )
  }
}
