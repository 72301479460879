import { Component, OnInit, ViewContainerRef } from '@angular/core'
import { NzModalService } from 'ng-zorro-antd/modal'
import { QueryTable } from 'src/app/services/interface/general.model'
import { NzTableQueryParams, NzTableFilterList, NzTableSortOrder } from 'ng-zorro-antd/table'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { LeaveService } from 'src/app/services/leave.service'
import { Leave } from 'src/app/services/interface/leave.model'
import { AddLeaveComponent } from './add-leave/add-leave.component'
import { ViewLeaveComponent } from './view-leave/view-leave.component'

interface ColumnItem {
  name: string
  key: string
  sortOrder: NzTableSortOrder | null
  sortFn: null | boolean
  listOfFilter: NzTableFilterList
  filterFn: null
  left: boolean
  right: boolean
  width: string
}

@Component({
  selector: 'app-take-leave',
  templateUrl: './take-leave.component.html',
})
export class TakeLeaveComponent implements OnInit {
  listOfData: Leave[] = []

  isLoading: boolean = false
  totalData: number = 0
  pageSize: number = 25
  pageIndex: number = 1
  form: FormGroup
  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private leaveService: LeaveService,
    private fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      keyword: [null],
      prefix: ['name', Validators.compose([Validators.required])],
    })
  }

  queryTable: QueryTable = {
    sort: null,
    filters: [],
    pageSize: this.pageSize,
    pageIndex: this.pageIndex - 1,
  }

  listOfColumns: ColumnItem[] = [
    {
      name: 'Actions',
      key: 'actions',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: true,
      right: false,
      width: '70px',
    },
    {
      name: 'Applied At',
      key: 'created_at',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '190px',
    },
    {
      name: 'Substitute',
      key: 'substitute',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '200px',
    },
    {
      name: 'Leave Notes',
      key: 'leave_notes',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '200px',
    },
    {
      name: 'Status',
      key: 'status',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '200px',
    },
  ]

  ngOnInit(): void {
    this.fetchUserLeave()
  }

  fetchUserLeave() {
    this.isLoading = true
    this.leaveService.listLeaveUser(this.queryTable).subscribe((r: any) => {
      this.isLoading = false
      this.listOfData = r.data
      this.totalData = r.total
    })
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort, filter } = params
    this.queryTable.pageSize = pageSize
    this.queryTable.pageIndex = pageIndex - 1
    const currentSort = sort.find(item => item.value !== null)
    const sortField = (currentSort && currentSort.key) || null
    const sortOrder = (currentSort && currentSort.value) || null

    if (sortField != null && sortOrder != null) {
      this.queryTable.sort = {
        field: sortField,
        order: sortOrder,
      }
    } else {
      this.queryTable.sort = null
    }
    this.fetchUserLeave()
  }

  search() {
    if (this.form.value.keyword?.length > 0) {
      this.queryTable.filters = [
        {
          field: this.form.value.prefix,
          keyword: this.form.value.keyword,
        },
      ]
    } else {
      this.queryTable.filters = []
    }
    this.fetchUserLeave()
  }

  reset() {
    this.form.reset()
    this.form.patchValue({ prefix: null })
    this.queryTable.sort = null
    this.queryTable.filters = []
    this.listOfColumns.forEach(r => {
      r.sortOrder = null
    })
    this.fetchUserLeave()
  }

  trackByName(_: number, item: ColumnItem): string {
    return item.name
  }

  addLeave() {
    const modal = this.modal.create({
      nzTitle: 'Request New Leave',
      nzContent: AddLeaveComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {},
      nzMaskClosable: false,
      nzClosable: false,
      nzOkText: 'Create',
      nzOkDisabled: true,
      // nzOnCancel: () => {
      //   console.log('CANCEL')
      // },
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          // instance.submitForm()
        }),
      nzWidth: '75%',
    })
    const instance = modal.getContentComponent()
    modal.afterClose.subscribe(() => {
      this.fetchUserLeave()
    })
  }

  async viewLeave(id: string) {
    const modal = this.modal.create({
      nzTitle: 'View Leave Request',
      nzContent: ViewLeaveComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {
        id: id,
      },
      nzMaskClosable: false,
      nzClosable: true,
      nzWidth: '75%',
      nzFooter: [
        {
          label: 'Close',
          onClick: () => modal.destroy(),
        },
      ],
    })
    const instance = modal.getContentComponent()
    modal.afterClose.subscribe(() => {
      this.fetchUserLeave()
    })
  }
}
